import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

type Props = {
    title ?:any
    description ?:any
    subtitle ?:any
    isHighlighted ?: boolean
    countries ?: any

}


const PriceCard: FC<Props> = ({title , description, subtitle, isHighlighted, countries}) => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('pricing.features.comingSoon')}
        </Tooltip>
    );
    const handleSubscribeButtonClick = () => {
        setShowModal(true);
    };
    return (
        <>
            <div style={isHighlighted ? {  boxShadow: '0px 0px 30px 0px #CCCCCC' , transform: 'scale(1.04)', border: "2px solid #ABEC78" } : {}}>
               <div  className={'bg-purple position-relative p-3 card-hero '}>
                     <div className={'inline-title'}>{t('pricing.enterprise.title')}</div>
                    <div className={'sm-txt mt-3 h-140px'} >
                        {t('pricing.enterprise.description')}
                    </div>
                </div>
                <div className={'title-price bg-light-purple '}>
                    <div className={'h-70px pt-lg-3 pt-1'} dangerouslySetInnerHTML={{__html: t('pricing.enterprise.subtitle')}} />
                </div>


                <div className={'bg-white'}>
                    <div className={'features'}>
                        <ul>
                            <li>{t('pricing.features.one')} <span className="check"><img src="/assets/images/check.png"  alt={'Spacefit'}/></span></li>
                            <li>{t('pricing.features.two')} <span className="check"><img src="/assets/images/check.png"  alt={'Spacefit'}/></span></li>
                            <li>{t('pricing.features.three')}<span className="check"><img src="/assets/images/check.png"  alt={'Spacefit'}/></span></li>
                            <li>{t('pricing.features.four')} <span className="check"><img src="/assets/images/check.png"  alt={'Spacefit'}/></span></li>
                            <li>{t('pricing.features.five')} <span className="check"><img src="/assets/images/check.png"  alt={'Spacefit'}/></span></li>
                            <li>{t('pricing.features.six')} <span className="check"><img src="/assets/images/check.png"  alt={'Spacefit'}/></span></li>
                            <li>{t('pricing.features.nine')} <span className="check"><img src="/assets/images/check.png"  alt={'Spacefit'}/></span></li>
                            <li>{t('pricing.features.ten')} <span className="check"><img src="/assets/images/check.png"  alt={'Spacefit'}/></span></li>
                            <li className="tooltip-container">

                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                >
                                    <div >  {t('pricing.features.seven')}<span className="check in-check">
    <img src="/assets/images/in-check.png" alt="Check icon" />
  </span></div>
                                </OverlayTrigger>

                            </li>
                            <li className="tooltip-container">

                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                >
                                    <div >  {t('pricing.features.eight')}<span className="check in-check">
    <img src="/assets/images/in-check.png" alt="Check icon" />
  </span></div>
                                </OverlayTrigger>

                            </li>
                        </ul>

                        <div className={'row justify-content-center'}>
                            <div className={'col-md-11'}>
                                <button className="btn-secondary-grey w-100"><span dangerouslySetInnerHTML={{__html: t('pricing.enterprise.button')}} /></button>


                            </div>
                            <div className={'col-md-11'}>
                                <button className="btn btn-nav-primary p-btn w-100 ms-0"
                                        onClick={handleSubscribeButtonClick}>{t('buttons.comingSoon')}</button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            {/*{countries &&*/}
            {/*    <PurchaseForm showModal={showModal} onHide={() => setShowModal(false)} countries={countries} price={0} packageId={4}  paymentGateway={null}/>*/}
            {/*}*/}

        </>
    )
}
export default PriceCard;