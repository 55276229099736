/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter} from 'react-router-dom'
import {App} from "../App";
import Home from "../pages/home/Home";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import Technology from "../pages/technology/technology";
import Pricing from "../pages/pricing/Pricing";
import About from "../pages/about/About";
import BookDemo from "../pages/contact/BookDemo";
import Terms from "../pages/Terms/Terms";
import Privacy from "../pages/privacy/Privacy";
import Contact from "../pages/contact/Contact";
import Payment from "../pages/payment";


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ["en", "fr"],
        fallbackLng: "en",
        detection: {
            order: ["path", "cookie", "htmlTag"],
            caches: ["cookie"],
        },
        backend: {
            loadPath: "/assets/locales/{{lng}}/translation.json",
        },
        preload: ["en", "fr" ],
    });
const AppRoutes: FC = () => {
    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App />}>
                    <Route path='/:lang?/payment/:status' element={<Payment />} />

                    <Route path='/:lang?/technologie' element={<Technology />} />
                    <Route path='/:lang?/licences' element={<Pricing />} />

                    <Route path='/:lang?/societe' element={<About />} />
                    <Route path='/:lang?/demande-demo' element={<BookDemo />} />
                    <Route path='/:lang?/contact' element={<Contact />} />

                    <Route path='/:lang?/conditions-generale-utilisation' element={<Terms />} />
                    <Route path='/:lang?/politique-protection-donnees-personnelles' element={<Privacy />} />

                    <Route path='/:lang?' element={<Home />} />


                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export {AppRoutes}
