import React from "react";
import Hero from "./components/Hero";
import Team from "./components/Team";
import {SocialMeta} from "../../layout/base/social-meta";
import {t} from "i18next";


const About = () => {
    return (
        <>
            <SocialMeta title={t('navigation.about') } />
            <Hero />
            <Team />

        </>
    );
};

export default About;
