import React from "react";
import Hero from "./components/Hero";
import {SocialMeta} from "../../layout/base/social-meta";
import {t} from "i18next";

const Pricing = () => {
    return (
        <>
            <SocialMeta title={t('navigation.pricing') } />
            <Hero/>
        </>
    );
};

export default Pricing;
