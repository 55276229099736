import React from "react";
import {SocialMeta} from "../../layout/base/social-meta";
import {t} from "i18next";
import CalendlyComponent from "./components/CalendlyComponent";

const BookDemo = () => {

    return (
        <>
            <SocialMeta title={'Demande une demo légales'}/>
            <section className={'bg-light-color position-relative'}>
                <div className={'d-lg-block d-none'}>
                    <div className="bg-equipe1-right"><img src="/assets/images/bg-g-devis.png" alt={'Spacefit'}/></div>
                    <div className="bg-equipe1-top"><img src="/assets/images/bg-d-devis.png"  alt={'Spacefit'}/></div>
                    <div className="bg-equipe1-bottom"><img src="/assets/images/bg-b-devis.png" alt={'Spacefit'} /></div>
                </div>


                <div className={'container'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-10 mt-5'}>
                            <div className={'bg-primary-color'}>
                                <h1 className={'main-h1'}>{t('contact.title')}</h1>
                            </div>
                        </div>
                    </div>

                    <div className={'row justify-content-center'}>
                        <div className={'form-group row'}>
                            <div className={'col-md-12  mb-2'}>
                                <CalendlyComponent/>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>

    );
};

export default BookDemo;
