import React from "react";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";


const ExplorePower = () => {
    const {t} = useTranslation();
    const currentLanguageCode = Cookies.get("i18next") || "en";
    return (
        <section className={'explore '}>
            <div className={' position-relative pb-5 '}>
                <div className="bg-techn d-xl-block d-lg-none d-md-none d-none "><img src="/assets/images/bg_techno.svg"  alt={'Spacefit'}/></div>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <h1 dangerouslySetInnerHTML={{__html: t('explore.title')}}/>
                        </div>
                    </div>

                    <div className={'row justify-content-center  position-relative '}>
                        <div className={'col-xl-8 col-lg-8 col-md-12 mb-3'}>
                            <div className={'text-center'}>
                                <p>
                                    {t('explore.description')}</p>
                            </div>
                        </div>

                        <div
                            className={`col-xl-10 col-lg-10 col-md-12 ${currentLanguageCode === 'en' ? 'mt-xl-4 pt-xl-3 pt-lg-3 mt-lg-3 mt-3 pt-3' : ' pt-xl-3 pt-lg-3 mt-lg-0 mt-3 pt-3'}`}>
                            <div className={'explore-description'}
                                 dangerouslySetInnerHTML={{__html: t('explore.power1')}}/>


                            <div className={`explore-description mt-lg-r `}
                                 dangerouslySetInnerHTML={{__html: t('explore.power2')}}/>


                            <div className={`explore-description mt-lg-r `}
                                 dangerouslySetInnerHTML={{__html: t('explore.power3')}}/>

                            <div className={`explore-description  mt-lg-r`}
                                 dangerouslySetInnerHTML={{__html: t('explore.power4')}}/>

                            <div className={'explore-description mt-lg-r mb-5'}
                                 dangerouslySetInnerHTML={{__html: t('explore.power5')}}/>

                        </div>

                        <div className={'d-xl-block d-lg-none d-md-none d-none left-layer'}>
                            <img src={'/assets/images/left-technologie.png'} alt={'Spacefit'}/>
                        </div>


                    </div>

                </div>


                <div className="titre-bas">{t('explore.label')}</div>
            </div>
        </section>
    )
}
export default ExplorePower;