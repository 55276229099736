export const API_URL = "https://api.spacefit.thewickfirm.com/api";
export const PAYMENT_URL = `https://api.spacefit.ai/api/v1/payments`;
export const COUNTRIES = [

    {
        "id": 1,
        "label": "United States",
        "value": "US"
    },
    {
        "id": 2,
        "label": "Canada",
        "value": "CA"
    },
    {
        "id": 3,
        "label": "Afghanistan",
        "value": "AF"
    },
    {
        "id": 4,
        "label": "Albania",
        "value": "AL"
    },
    {
        "id": 5,
        "label": "Algeria",
        "value": "DZ"
    },
    {
        "id": 6,
        "label": "American Samoa",
        "value": "AS"
    },
    {
        "id": 7,
        "label": "Andorra",
        "value": "AD"
    },
    {
        "id": 8,
        "label": "Angola",
        "value": "AO"
    },
    {
        "id": 9,
        "label": "Anguilla",
        "value": "AI"
    },
    {
        "id": 10,
        "label": "Antarctica",
        "value": "AQ"
    },
    {
        "id": 11,
        "label": "Antigua and/or Barbuda",
        "value": "AG"
    },
    {
        "id": 12,
        "label": "Argentina",
        "value": "AR"
    },
    {
        "id": 13,
        "label": "Armenia",
        "value": "AM"
    },
    {
        "id": 14,
        "label": "Aruba",
        "value": "AW"
    },
    {
        "id": 15,
        "label": "Australia",
        "value": "AU"
    },
    {
        "id": 16,
        "label": "Austria",
        "value": "AT"
    },
    {
        "id": 17,
        "label": "Azerbaijan",
        "value": "AZ"
    },
    {
        "id": 18,
        "label": "Bahamas",
        "value": "BS"
    },
    {
        "id": 19,
        "label": "Bahrain",
        "value": "BH"
    },
    {
        "id": 20,
        "label": "Bangladesh",
        "value": "BD"
    },
    {
        "id": 21,
        "label": "Barbados",
        "value": "BB"
    },
    {
        "id": 22,
        "label": "Belarus",
        "value": "BY"
    },
    {
        "id": 23,
        "label": "Belgium",
        "value": "BE"
    },
    {
        "id": 24,
        "label": "Belize",
        "value": "BZ"
    },
    {
        "id": 25,
        "label": "Benin",
        "value": "BJ"
    },
    {
        "id": 26,
        "label": "Bermuda",
        "value": "BM"
    },
    {
        "id": 27,
        "label": "Bhutan",
        "value": "BT"
    },
    {
        "id": 28,
        "label": "Bolivia",
        "value": "BO"
    },
    {
        "id": 29,
        "label": "Bosnia and Herzegovina",
        "value": "BA"
    },
    {
        "id": 30,
        "label": "Botswana",
        "value": "BW"
    },
    {
        "id": 31,
        "label": "Bouvet Island",
        "value": "BV"
    },
    {
        "id": 32,
        "label": "Brazil",
        "value": "BR"
    },
    {
        "id": 33,
        "label": "British lndian Ocean Territory",
        "value": "IO"
    },
    {
        "id": 34,
        "label": "Brunei Darussalam",
        "value": "BN"
    },
    {
        "id": 35,
        "label": "Bulgaria",
        "value": "BG"
    },
    {
        "id": 36,
        "label": "Burkina Faso",
        "value": "BF"
    },
    {
        "id": 37,
        "label": "Burundi",
        "value": "BI"
    },
    {
        "id": 38,
        "label": "Cambodia",
        "value": "KH"
    },
    {
        "id": 39,
        "label": "Cameroon",
        "value": "CM"
    },
    {
        "id": 40,
        "label": "Cape Verde",
        "value": "CV"
    },
    {
        "id": 41,
        "label": "Cayman Islands",
        "value": "KY"
    },
    {
        "id": 42,
        "label": "Central African Republic",
        "value": "CF"
    },
    {
        "id": 43,
        "label": "Chad",
        "value": "TD"
    },
    {
        "id": 44,
        "label": "Chile",
        "value": "CL"
    },
    {
        "id": 45,
        "label": "China",
        "value": "CN"
    },
    {
        "id": 46,
        "label": "Christmas Island",
        "value": "CX"
    },
    {
        "id": 47,
        "label": "Cocos (Keeling) Islands",
        "value": "CC"
    },
    {
        "id": 48,
        "label": "Colombia",
        "value": "CO"
    },
    {
        "id": 49,
        "label": "Comoros",
        "value": "KM"
    },
    {
        "id": 50,
        "label": "Congo",
        "value": "CG"
    },
    {
        "id": 51,
        "label": "Cook Islands",
        "value": "CK"
    },
    {
        "id": 52,
        "label": "Costa Rica",
        "value": "CR"
    },
    {
        "id": 53,
        "label": "Croatia (Hrvatska)",
        "value": "HR"
    },
    {
        "id": 54,
        "label": "Cuba",
        "value": "CU"
    },
    {
        "id": 55,
        "label": "Cyprus",
        "value": "CY"
    },
    {
        "id": 56,
        "label": "Czech Republic",
        "value": "CZ"
    },
    {
        "id": 57,
        "label": "Democratic Republic of Congo",
        "value": "CD"
    },
    {
        "id": 58,
        "label": "Denmark",
        "value": "DK"
    },
    {
        "id": 59,
        "label": "Djibouti",
        "value": "DJ"
    },
    {
        "id": 60,
        "label": "Dominica",
        "value": "DM"
    },
    {
        "id": 61,
        "label": "Dominican Republic",
        "value": "DO"
    },
    {
        "id": 62,
        "label": "East Timor",
        "value": "TP"
    },
    {
        "id": 63,
        "label": "Ecudaor",
        "value": "EC"
    },
    {
        "id": 64,
        "label": "Egypt",
        "value": "EG"
    },
    {
        "id": 65,
        "label": "El Salvador",
        "value": "SV"
    },
    {
        "id": 66,
        "label": "Equatorial Guinea",
        "value": "GQ"
    },
    {
        "id": 67,
        "label": "Eritrea",
        "value": "ER"
    },
    {
        "id": 68,
        "label": "Estonia",
        "value": "EE"
    },
    {
        "id": 69,
        "label": "Ethiopia",
        "value": "ET"
    },
    {
        "id": 70,
        "label": "Falkland Islands (Malvinas)",
        "value": "FK"
    },
    {
        "id": 71,
        "label": "Faroe Islands",
        "value": "FO"
    },
    {
        "id": 72,
        "label": "Fiji",
        "value": "FJ"
    },
    {
        "id": 73,
        "label": "Finland",
        "value": "FI"
    },
    {
        "id": 74,
        "label": "France",
        "value": "FR"
    },
    {
        "id": 75,
        "label": "France, Metropolitan",
        "value": "FX"
    },
    {
        "id": 76,
        "label": "French Guiana",
        "value": "GF"
    },
    {
        "id": 77,
        "label": "French Polynesia",
        "value": "PF"
    },
    {
        "id": 78,
        "label": "French Southern Territories",
        "value": "TF"
    },
    {
        "id": 79,
        "label": "Gabon",
        "value": "GA"
    },
    {
        "id": 80,
        "label": "Gambia",
        "value": "GM"
    },
    {
        "id": 81,
        "label": "Georgia",
        "value": "GE"
    },
    {
        "id": 82,
        "label": "Germany",
        "value": "DE"
    },
    {
        "id": 83,
        "label": "Ghana",
        "value": "GH"
    },
    {
        "id": 84,
        "label": "Gibraltar",
        "value": "GI"
    },
    {
        "id": 85,
        "label": "Greece",
        "value": "GR"
    },
    {
        "id": 86,
        "label": "Greenland",
        "value": "GL"
    },
    {
        "id": 87,
        "label": "Grenada",
        "value": "GD"
    },
    {
        "id": 88,
        "label": "Guadeloupe",
        "value": "GP"
    },
    {
        "id": 89,
        "label": "Guam",
        "value": "GU"
    },
    {
        "id": 90,
        "label": "Guatemala",
        "value": "GT"
    },
    {
        "id": 91,
        "label": "Guinea",
        "value": "GN"
    },
    {
        "id": 92,
        "label": "Guinea-Bissau",
        "value": "GW"
    },
    {
        "id": 93,
        "label": "Guyana",
        "value": "GY"
    },
    {
        "id": 94,
        "label": "Haiti",
        "value": "HT"
    },
    {
        "id": 95,
        "label": "Heard and Mc Donald Islands",
        "value": "HM"
    },
    {
        "id": 96,
        "label": "Honduras",
        "value": "HN"
    },
    {
        "id": 97,
        "label": "Hong Kong",
        "value": "HK"
    },
    {
        "id": 98,
        "label": "Hungary",
        "value": "HU"
    },
    {
        "id": 99,
        "label": "Iceland",
        "value": "IS"
    },
    {
        "id": 100,
        "label": "India",
        "value": "IN"
    },
    {
        "id": 101,
        "label": "Indonesia",
        "value": "ID"
    },
    {
        "id": 102,
        "label": "Iran (Islamic Republic of)",
        "value": "IR"
    },
    {
        "id": 103,
        "label": "Iraq",
        "value": "IQ"
    },
    {
        "id": 104,
        "label": "Ireland",
        "value": "IE"
    },
    {
        "id": 105,
        "label": "Israel",
        "value": "IL"
    },
    {
        "id": 106,
        "label": "Italy",
        "value": "IT"
    },
    {
        "id": 107,
        "label": "Ivory Coast",
        "value": "CI"
    },
    {
        "id": 108,
        "label": "Jamaica",
        "value": "JM"
    },
    {
        "id": 109,
        "label": "Japan",
        "value": "JP"
    },
    {
        "id": 110,
        "label": "Jordan",
        "value": "JO"
    },
    {
        "id": 111,
        "label": "Kazakhstan",
        "value": "KZ"
    },
    {
        "id": 112,
        "label": "Kenya",
        "value": "KE"
    },
    {
        "id": 113,
        "label": "Kiribati",
        "value": "KI"
    },
    {
        "id": 114,
        "label": "Korea, Democratic People's Republic of",
        "value": "KP"
    },
    {
        "id": 115,
        "label": "Korea, Republic of",
        "value": "KR"
    },
    {
        "id": 116,
        "label": "Kuwait",
        "value": "KW"
    },
    {
        "id": 117,
        "label": "Kyrgyzstan",
        "value": "KG"
    },
    {
        "id": 118,
        "label": "Lao People's Democratic Republic",
        "value": "LA"
    },
    {
        "id": 119,
        "label": "Latvia",
        "value": "LV"
    },
    {
        "id": 120,
        "label": "Lebanon",
        "value": "LB"
    },
    {
        "id": 121,
        "label": "Lesotho",
        "value": "LS"
    },
    {
        "id": 122,
        "label": "Liberia",
        "value": "LR"
    },
    {
        "id": 123,
        "label": "Libyan Arab Jamahiriya",
        "value": "LY"
    },
    {
        "id": 124,
        "label": "Liechtenstein",
        "value": "LI"
    },
    {
        "id": 125,
        "label": "Lithuania",
        "value": "LT"
    },
    {
        "id": 126,
        "label": "Luxembourg",
        "value": "LU"
    },
    {
        "id": 127,
        "label": "Macau",
        "value": "MO"
    },
    {
        "id": 128,
        "label": "Macedonia",
        "value": "MK"
    },
    {
        "id": 129,
        "label": "Madagascar",
        "value": "MG"
    },
    {
        "id": 130,
        "label": "Malawi",
        "value": "MW"
    },
    {
        "id": 131,
        "label": "Malaysia",
        "value": "MY"
    },
    {
        "id": 132,
        "label": "Maldives",
        "value": "MV"
    },
    {
        "id": 133,
        "label": "Mali",
        "value": "ML"
    },
    {
        "id": 134,
        "label": "Malta",
        "value": "MT"
    },
    {
        "id": 135,
        "label": "Marshall Islands",
        "value": "MH"
    },
    {
        "id": 136,
        "label": "Martinique",
        "value": "MQ"
    },
    {
        "id": 137,
        "label": "Mauritania",
        "value": "MR"
    },
    {
        "id": 138,
        "label": "Mauritius",
        "value": "MU"
    },
    {
        "id": 139,
        "label": "Mayotte",
        "value": "TY"
    },
    {
        "id": 140,
        "label": "Mexico",
        "value": "MX"
    },
    {
        "id": 141,
        "label": "Micronesia, Federated States of",
        "value": "FM"
    },
    {
        "id": 142,
        "label": "Moldova, Republic of",
        "value": "MD"
    },
    {
        "id": 143,
        "label": "Monaco",
        "value": "MC"
    },
    {
        "id": 144,
        "label": "Mongolia",
        "value": "MN"
    },
    {
        "id": 145,
        "label": "Montserrat",
        "value": "MS"
    },
    {
        "id": 146,
        "label": "Morocco",
        "value": "MA"
    },
    {
        "id": 147,
        "label": "Mozambique",
        "value": "MZ"
    },
    {
        "id": 148,
        "label": "Myanmar",
        "value": "MM"
    },
    {
        "id": 149,
        "label": "Namibia",
        "value": "NA"
    },
    {
        "id": 150,
        "label": "Nauru",
        "value": "NR"
    },
    {
        "id": 151,
        "label": "Nepal",
        "value": "NP"
    },
    {
        "id": 152,
        "label": "Netherlands",
        "value": "NL"
    },
    {
        "id": 153,
        "label": "Netherlands Antilles",
        "value": "AN"
    },
    {
        "id": 154,
        "label": "New Caledonia",
        "value": "NC"
    },
    {
        "id": 155,
        "label": "New Zealand",
        "value": "NZ"
    },
    {
        "id": 156,
        "label": "Nicaragua",
        "value": "NI"
    },
    {
        "id": 157,
        "label": "Niger",
        "value": "NE"
    },
    {
        "id": 158,
        "label": "Nigeria",
        "value": "NG"
    },
    {
        "id": 159,
        "label": "Niue",
        "value": "NU"
    },
    {
        "id": 160,
        "label": "Norfork Island",
        "value": "NF"
    },
    {
        "id": 161,
        "label": "Northern Mariana Islands",
        "value": "MP"
    },
    {
        "id": 162,
        "label": "Norway",
        "value": "NO"
    },
    {
        "id": 163,
        "label": "Oman",
        "value": "OM"
    },
    {
        "id": 164,
        "label": "Pakistan",
        "value": "PK"
    },
    {
        "id": 165,
        "label": "Palau",
        "value": "PW"
    },
    {
        "id": 166,
        "label": "Panama",
        "value": "PA"
    },
    {
        "id": 167,
        "label": "Papua New Guinea",
        "value": "PG"
    },
    {
        "id": 168,
        "label": "Paraguay",
        "value": "PY"
    },
    {
        "id": 169,
        "label": "Peru",
        "value": "PE"
    },
    {
        "id": 170,
        "label": "Philippines",
        "value": "PH"
    },
    {
        "id": 171,
        "label": "Pitcairn",
        "value": "PN"
    },
    {
        "id": 172,
        "label": "Poland",
        "value": "PL"
    },
    {
        "id": 173,
        "label": "Portugal",
        "value": "PT"
    },
    {
        "id": 174,
        "label": "Puerto Rico",
        "value": "PR"
    },
    {
        "id": 175,
        "label": "Qatar",
        "value": "QA"
    },
    {
        "id": 176,
        "label": "Republic of South Sudan",
        "value": "SS"
    },
    {
        "id": 177,
        "label": "Reunion",
        "value": "RE"
    },
    {
        "id": 178,
        "label": "Romania",
        "value": "RO"
    },
    {
        "id": 179,
        "label": "Russian Federation",
        "value": "RU"
    },
    {
        "id": 180,
        "label": "Rwanda",
        "value": "RW"
    },
    {
        "id": 181,
        "label": "Saint Kitts and Nevis",
        "value": "KN"
    },
    {
        "id": 182,
        "label": "Saint Lucia",
        "value": "LC"
    },
    {
        "id": 183,
        "label": "Saint Vincent and the Grenadines",
        "value": "VC"
    },
    {
        "id": 184,
        "label": "Samoa",
        "value": "WS"
    },
    {
        "id": 185,
        "label": "San Marino",
        "value": "SM"
    },
    {
        "id": 186,
        "label": "Sao Tome and Principe",
        "value": "ST"
    },
    {
        "id": 187,
        "label": "Saudi Arabia",
        "value": "SA"
    },
    {
        "id": 188,
        "label": "Senegal",
        "value": "SN"
    },
    {
        "id": 189,
        "label": "Serbia",
        "value": "RS"
    },
    {
        "id": 190,
        "label": "Seychelles",
        "value": "SC"
    },
    {
        "id": 191,
        "label": "Sierra Leone",
        "value": "SL"
    },
    {
        "id": 192,
        "label": "Singapore",
        "value": "SG"
    },
    {
        "id": 193,
        "label": "Slovakia",
        "value": "SK"
    },
    {
        "id": 194,
        "label": "Slovenia",
        "value": "SI"
    },
    {
        "id": 195,
        "label": "Solomon Islands",
        "value": "SB"
    },
    {
        "id": 196,
        "label": "Somalia",
        "value": "SO"
    },
    {
        "id": 197,
        "label": "South Africa",
        "value": "ZA"
    },
    {
        "id": 198,
        "label": "South Georgia South Sandwich Islands",
        "value": "GS"
    },
    {
        "id": 199,
        "label": "Spain",
        "value": "ES"
    },
    {
        "id": 200,
        "label": "Sri Lanka",
        "value": "LK"
    },
    {
        "id": 201,
        "label": "St. Helena",
        "value": "SH"
    },
    {
        "id": 202,
        "label": "St. Pierre and Miquelon",
        "value": "PM"
    },
    {
        "id": 203,
        "label": "Sudan",
        "value": "SD"
    },
    {
        "id": 204,
        "label": "Suriname",
        "value": "SR"
    },
    {
        "id": 205,
        "label": "Svalbarn and Jan Mayen Islands",
        "value": "SJ"
    },
    {
        "id": 206,
        "label": "Swaziland",
        "value": "SZ"
    },
    {
        "id": 207,
        "label": "Sweden",
        "value": "SE"
    },
    {
        "id": 208,
        "label": "Switzerland",
        "value": "CH"
    },
    {
        "id": 209,
        "label": "Syrian Arab Republic",
        "value": "SY"
    },
    {
        "id": 210,
        "label": "Taiwan",
        "value": "TW"
    },
    {
        "id": 211,
        "label": "Tajikistan",
        "value": "TJ"
    },
    {
        "id": 212,
        "label": "Tanzania, United Republic of",
        "value": "TZ"
    },
    {
        "id": 213,
        "label": "Thailand",
        "value": "TH"
    },
    {
        "id": 214,
        "label": "Togo",
        "value": "TG"
    },
    {
        "id": 215,
        "label": "Tokelau",
        "value": "TK"
    },
    {
        "id": 216,
        "label": "Tonga",
        "value": "TO"
    },
    {
        "id": 217,
        "label": "Trinidad and Tobago",
        "value": "TT"
    },
    {
        "id": 218,
        "label": "Tunisia",
        "value": "TN"
    },
    {
        "id": 219,
        "label": "Turkey",
        "value": "TR"
    },
    {
        "id": 220,
        "label": "Turkmenistan",
        "value": "TM"
    },
    {
        "id": 221,
        "label": "Turks and Caicos Islands",
        "value": "TC"
    },
    {
        "id": 222,
        "label": "Tuvalu",
        "value": "TV"
    },
    {
        "id": 223,
        "label": "Uganda",
        "value": "UG"
    },
    {
        "id": 224,
        "label": "Ukraine",
        "value": "UA"
    },
    {
        "id": 225,
        "label": "United Arab Emirates",
        "value": "AE"
    },
    {
        "id": 226,
        "label": "United Kingdom",
        "value": "GB"
    },
    {
        "id": 227,
        "label": "United States minor outlying islands",
        "value": "UM"
    },
    {
        "id": 228,
        "label": "Uruguay",
        "value": "UY"
    },
    {
        "id": 229,
        "label": "Uzbekistan",
        "value": "UZ"
    },
    {
        "id": 230,
        "label": "Vanuatu",
        "value": "VU"
    },
    {
        "id": 231,
        "label": "Vatican City State",
        "value": "VA"
    },
    {
        "id": 232,
        "label": "Venezuela",
        "value": "VE"
    },
    {
        "id": 233,
        "label": "Vietnam",
        "value": "VN"
    },
    {
        "id": 234,
        "label": "Virgin Islands (British)",
        "value": "VG"
    },
    {
        "id": 235,
        "label": "Virgin Islands (U.S.)",
        "value": "VI"
    },
    {
        "id": 236,
        "label": "Wallis and Futuna Islands",
        "value": "WF"
    },
    {
        "id": 237,
        "label": "Western Sahara",
        "value": "EH"
    },
    {
        "id": 238,
        "label": "Yemen",
        "value": "YE"
    },
    {
        "id": 239,
        "label": "Yugoslavia",
        "value": "YU"
    },
    {
        "id": 240,
        "label": "Zaire",
        "value": "ZR"
    },
    {
        "id": 241,
        "label": "Zambia",
        "value": "ZM"
    },
    {
        "id": 242,
        "label": "Zimbabwe",
        "value": "ZW"
    }
]


