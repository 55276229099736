import React, {FC} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

interface Props {
    title: any;
    description?: any;
    image?: string;
    name?: string
    link ?: any
    keyword ?:string
}

const SocialMeta:  FC<Props> = ({ title, description, image, name,link , keyword}) => {
    return (
        <div>
            <HelmetProvider>
            <Helmet>
                <title>{title}</title>
                {/*<meta name="description" content={description} />*/}
                {/*<meta name="keywords" content={keyword} />*/}
                {/* /!*Schema.org markup for Google*!/*/}
                {/*<meta itemProp="name" content={name} />*/}
                {/*<meta itemProp="description" content={description} />*/}
                {/*/!*<meta itemProp="image" content={image} />*!/*/}

                {/*/!*Twitter Card data*!/*/}
                {/*<meta name="twitter:card" content="summary_large_image"/>*/}
                {/*<meta name="twitter:title" content={name}/>*/}
                {/*<meta name="twitter:description" content={description}/>*/}
                {/*/!*<meta name="twitter:image" content={image}/>*!/*/}
                {/*/!*<meta name="twitter:image:src" content={image}/>*!/*/}


                {/*/!*Open Graph data*!/*/}
                {/*<meta property="og:site_name" content={name}/>*/}
                {/*<meta property="og:type" content="website"/>*/}
                {/*<meta property="og:description" content={description}/>*/}
                {/*/!*<meta property="og:image" content={image}/>*!/*/}
                {/*<meta property="og:url" content={link && link}/>*/}
                {/*<meta property="og:title" content={name}/>*/}

            </Helmet>
            </HelmetProvider>
        </div>
    );
};

export {SocialMeta};
