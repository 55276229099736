import React from "react";
import {useTranslation} from "react-i18next";

const Hero = () => {
    const {t} = useTranslation();
    return (
        <>
            <section className=" ">
                <div className="container-fluid p-0">
                    <div className="section-top">
                        <div className="">
                            <div className="img-box">
                                <div className="img_box-inner position-relative bg-top-home top-team-img">
                                    <img alt={'Spacefit'} src="/assets/images/header_equipe.png" className={'w-100'}/>
                                        <div className="text-header-centered position-absolute w-75">
                                            <h1 dangerouslySetInnerHTML={{__html: t('team.title')}} />

                                            <h5>
                                                {t('team.description')}
                                            </h5>


                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Hero;