import React from "react";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";


const SimulateComponent = () => {
    const {t} = useTranslation();
    const currentLanguageCode = Cookies.get("i18next") || "en";
    return (
        <>
            <div className={'simulate'}>
                <div className={'container'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-10 mt-5'}>
                            <div className={'bg-primary-color'}>
                                <h1 className={'main-h1'}>{t('explore.simulate.title')}</h1>
                            </div>

                            <div className={'mt-4'}>
                                <p className={'p-common'} dangerouslySetInnerHTML={{__html: t('explore.simulate.description')}} />
                            </div>

                            <div className={'mt-4'}>
                                <img src={`/assets/images/simulate/${currentLanguageCode}/plan.svg`} className={'w-100'} alt={'Spacefit'} />
                            </div>
                        </div>

                        <div className={'col-md-11 mt-4 mb-4'}>
                            <p  className={'p-common'}  dangerouslySetInnerHTML={{__html: t('explore.simulate.conclusion')}} />
                        </div>
                        </div>
                    </div>
            </div>

        </>
    )
}
export default SimulateComponent;