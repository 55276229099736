import axios from 'axios'
import {API_URL} from "../../../helpers/crud-helper/consts";

const MISC_URL = `${API_URL}/misc`
const CONTACT_US_URL = `${API_URL}/contact`


const getCountries = (): Promise<any> => {
    return axios
        .get(`${MISC_URL}/countries`)
        .then((response) => response)
};
const contactUs = (value : any): Promise<any> => {
    return axios
        .post(CONTACT_US_URL, value)
        .then((response) => response)
}
export {getCountries , contactUs}