import React from "react";
import {useTranslation} from "react-i18next";

const WhySpaceFit = () => {
    const {t} = useTranslation();
    return (
        <>
            <section className="why_section layout_padding mt-0">
                <div className={'container'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-10 mt-5'}>
                            <div className={'bg-primary-color'}>
                                <h1 className={'main-h1'}> {t('home.whySpace.title')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center" >
                        <div className="col-xl-3 col-lg-4 col-md-6 mt-2 mb-2 p-box">
                            <div className="flip-card" >
                                <div className="flip-card-inner">
                                    <div className="box ">
                                        <div className="img-box">

                                        </div>
                                        <div className="detail-box">
                                            <h5>
                                                10x
                                            </h5>
                                            <p>
                                                {t('home.whySpace.box1Title')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="box1">
                                        <div className="detail-box">
                                            <p dangerouslySetInnerHTML={{__html: t('home.whySpace.box1Description')}}>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 mt-2 mb-2 p-box">
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div className="box ">
                                        <div className="img-box">

                                        </div>
                                        <div className="detail-box">
                                            <h5>
                                                100x
                                            </h5>
                                            <p>
                                                {t('home.whySpace.box2Title')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="box1">
                                        <div className="detail-box">
                                            <p dangerouslySetInnerHTML={{__html: t('home.whySpace.box2Description')}}>

                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 mt-2 mb-2 p-box">
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div className="box ">
                                        <div className="img-box">

                                        </div>
                                        <div className="detail-box">
                                            <h5>
                                                1000x
                                            </h5>
                                            <p>
                                                {t('home.whySpace.box3Title')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="box1">
                                        <div className="detail-box">
                                            <p dangerouslySetInnerHTML={{__html :   t('home.whySpace.box3Description')}}>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-3 col-lg-4 col-md-6 mt-2 mb-2 p-box">
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div className="box ">
                                        <div className="img-box">

                                        </div>
                                        <div className="detail-box">
                                            <h5 >
                                                10 000x
                                            </h5>
                                            <p>
                                                {t('home.whySpace.box4Title')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="box1">
                                        <div className="detail-box">
                                            <div dangerouslySetInnerHTML={{__html : t('home.whySpace.box4Description')}} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default WhySpaceFit;