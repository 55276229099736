import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import PriceCardFreemium from "./PriceCardFreemium";
import PriceCardOne from "./PriceCardOne";
import PriceCardPremium from "./PriceCardPremium";
import PriceCard from "./PriceCard";
import {getCountries} from "../../contact/core/requests";

interface Option {
    value: string;
    label: string;
}


const Hero = () => {
    const {t} = useTranslation();
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [highLightedCard, setHighLightedCard] = useState<any>(null);
    const [countries, setCountries] = useState<any>([]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option: Option) => {
        setSelectedOption(option);
        setHighLightedCard(option)
        setIsOpen(false);
    };


    const options = [
        {value: '1', label: t('pricing.select.option1')},
        {value: '2', label: t('pricing.select.option2')},
        {value: '3', label: t('pricing.select.option3')},
        {value: '4', label: t('pricing.select.option4')},
        {value: '5', label: t('pricing.select.option5')},
        {value: '6', label: t('pricing.select.option6')}
    ];

    useEffect(() => {
        getCountries().then((response) => {
            setCountries(response.data.data);
        });
    } , [])
    return (
        <>
            <section className=" ">
                <div className="container-fluid p-0">
                    <div className="section-top">
                        <div className="">
                            <div className="img-box">
                                <div className="img_box-inner position-relative bg-top-home">
                                    <img src="/assets/images/activiter.png" className={'w-100'} alt={'Spacefit'}/>
                                    <div className="text-header-centered position-absolute">
                                        <div className={'d-xl-flex d-lg-flex d-block align-items-center'}>
                                            <h3 className="placeholder-select"><span
                                            >{t('pricing.select.placeholder')}</span></h3>
                                            {/*<SelectOption2 options={options}/>*/}
                                            <div className="custom-select">
                                                <div className="selected-option " onClick={toggleDropdown}>
                                                    {selectedOption ? selectedOption.label : <div className={'text-transparent'}> Select an option </div>}
                                                    <span className={`select-selected  ${isOpen ? 'select-arrow-active' : ''}`}></span>
                                                </div>
                                                {isOpen && (
                                                    <div className="options">
                                                        {options.map((option, index) => (
                                                            <div
                                                                key={option.value}
                                                                className={`option ${index === 0 ? 'first-option' : ''}`}
                                                                onClick={() => handleOptionClick(option)}
                                                            >
                                                                {option.label}
                                                                {index !== options.length - 1 && <hr className="option-divider" />}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={'bg-light-color'}>
                <div className={'container-fluid'}>
                    <div className={'row justify-content-center pt-5 pb-5'}>
                        <div className={'col-xl-3 col-lg-6 col-md-10 pe-lg-0 pb-2'}>
                            <PriceCardFreemium isHighlighted={selectedOption?.value === '5'} countries={countries}/>
                        </div>
                        <div className={'col-xl-3 col-lg-6 col-md-10 pe-lg-0 pb-2'} >
                            <PriceCardOne  isHighlighted={selectedOption?.value === '2'} countries={countries}/>
                        </div>
                        <div className={'col-xl-3 col-lg-6 col-md-10 pe-lg-0 pb-2'} >
                            <PriceCardPremium  isHighlighted={selectedOption?.value === '3' || selectedOption?.value === '4'} countries={countries}/>
                        </div>
                        <div className={'col-xl-3 col-lg-6 col-md-10 pe-lg-0 pb-2'} >
                            <PriceCard  isHighlighted={selectedOption?.value === '1' || selectedOption?.value === '6'} countries={countries}/>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}
export default Hero;