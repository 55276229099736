import React from "react";
import {Link, useParams} from "react-router-dom";
import {t} from "i18next";


const Payment = () => {
    const params = useParams()

    return (
        <div className={'bg-light'}>
            <div className={'container'}>
                <div className={'row'}>
                    {params.status === 'approved' ?
                        <div className={'  pt-3'}><h1 className={' pt-5 fs-40 mb-4'}> {t('payment.congratulation')}</h1>
                        <p className={'space-p'}>{t('payment.activePackage')} </p>
                        <p className={'space-p'}>
                            {t('payment.checkEmail')}
                        </p>
                            <div className={'col-md-4 mt-5'}>
                                <Link to={'https://app.spacefit.ai/auth/sign-in'} target={'_blank'} className="btn btn-nav-primary p-btn w-100 ms-0"
                                       >{t('payment.startHere')}</Link>
                                <p className={'space-p mt-2'} dangerouslySetInnerHTML={{__html: t('payment.contact')}} />
                            </div>
                        </div>
                        : params.status === 'pending' ?
                            <div className={'  h-400px'}><h1 className={'mt-5 pt-5'}>Pending Payment</h1></div>
                            : <div className={'  h-400px'}><h1 className={'mt-5 pt-5'}>Cancelled Payment</h1></div>}
                </div>
            </div>

        </div>
    );
};

export default Payment;