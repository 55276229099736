import React from "react";
import {useTranslation} from "react-i18next";
const Team = () => {
    const {t} = useTranslation();

    return (
        <section className="position-relative ">
                <div className={'container'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-10 mt-5'}>
                            <div className={'bg-primary-color'}>
                                <h1 className={'main-h1'}>{t('team.secondaryTitle')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'container'}>
                    <div className={'row justify-content-center mt-5 mb-5'}>
                        <div className={'col-md-2 col-8'}>
                            <div>
                                <img src={'/assets/images/photo-med.svg'} className={'bdr-all-dark w-100'} alt={'Team Spacefit'}/>
                            </div>
                        </div>
                        <div className={'col-md-8  pt-lg-0 pt-4'}>
                            <div className={'team-details'}>
                                <h5 dangerouslySetInnerHTML={{__html: t('team.team1')}}/>
                                <p>
                                    {t('team.description1')}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={'border-dot'}/>
                <div className="d-lg-block d-md-none d-none bg-equipe11"><img src="/assets/images/bg-first-equipe.png" alt={'Spacefit'}/></div>

                <div className={'position-relative'}>
                    <div className="d-lg-block d-md-none d-none bg-equipe1-left"><img src="/assets/images/bg-christophe-container.png" alt={' Spacefit'}/></div>
                    <div className={'container'}>
                        <div className={'row justify-content-center mt-5 mb-5'}>
                            <div className={'col-md-2 col-8 order-xl-2 order-lg-1 order-1'}>
                                <div>
                                    <img src={'/assets/images/bg-christophe.svg'} className={'bdr-all-dark w-100'} alt={'Team Spacefit'}/>
                                </div>
                            </div>
                            <div className={'col-md-8  pt-lg-0 pt-4 order-xl-1 order-lg-2 order-2'}>
                                <div className={'team-details to-right '}>
                                    <h5 dangerouslySetInnerHTML={{__html: t('team.team2')}}/>
                                    <p>
                                        {t('team.description2')}                                </p>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div className={'border-dot'}/>


                <div className={'position-relative'}>
                    <div className="d-lg-block d-md-none d-none bg-equipe1-right-1"><img src="/assets/images/bg-equipe3-container.png" alt={'Spacefit'}/></div>
                    <div className={'container'}>
                        <div className={'row justify-content-center mt-5 mb-5'}>
                            <div className={'col-md-2 col-8 '}>
                                <div>
                                    <img src={'/assets/images/my.svg'} className={'bdr-all-dark w-100'}alt={'Team Spacefit'}/>
                                </div>
                            </div>
                            <div className={'col-md-8  pt-lg-0 pt-4'}>
                                <div className={'team-details'}>
                                    <h5 dangerouslySetInnerHTML={{__html: t('team.team3')}}/>
                                    <p>
                                        {t('team.description3')}                                </p>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className={'border-dot'}/>
                <div className={'position-relative'}>
                    <div className="d-lg-block d-md-none d-none bg-equipe1-left"><img src="/assets/images/bg-equipe5-container.png" alt={'Spacefit'}/>
                    </div>
                    <div className={'container'}>
                        <div className={'row justify-content-center mt-5 mb-5'}>
                            <div className={'col-md-2 col-8 order-xl-2 order-lg-1 order-1'}>
                                <div>
                                    <img src={'/assets/images/anonym.svg'} className={'bdr-all-dark w-100'} alt={'Team Spacefit'}/>
                                </div>
                            </div>
                            <div className={'col-md-8 order-xl-1 order-lg-2 order-2  pt-lg-0 pt-4'}>
                                <div className={'team-details to-right'}>
                                    <h5 dangerouslySetInnerHTML={{__html: t('team.team4')}}/>
                                    <p>
                                        {t('team.description4')}
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className={'border-dot'}/>

                <div className={'position-relative'} >
                    <div className="d-lg-block d-md-none d-none bg-equipe1-right-1"><img src="/assets/images/bg-equipe4-container.png" alt={'Spacefit'}/>
                    </div>
                    <div className={'container'}>
                        <div className={'row justify-content-center mt-5 mb-5'}>
                            <div className={'col-md-2 col-8'}>
                                <div>
                                    <img src={'/assets/images/mich.svg'} className={'bdr-all-dark w-100'} alt={'Team Spacefit'}/>
                                </div>
                            </div>
                            <div className={'col-md-8  pt-lg-0 pt-4'}>
                                <div className={'team-details'}>
                                    <h5 dangerouslySetInnerHTML={{__html: t('team.team5')}}/>
                                    <p>
                                        {t('team.description5')}
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


                <div className={'border-dot'}/>
                <div className={'position-relative'}>
                    <div className="d-lg-block d-md-none d-none bg-equipe1-left"><img alt={' Spacefit'} className={'object-contain'} src="/assets/images/bg-container-last-equipe.png" />
                    </div>
                    <div className={'container'}>
                        <div className={'row justify-content-center mt-5 mb-5'}>
                            <div className={'col-md-2 col-8 order-xl-2 order-lg-1 order-1'}>
                                <div>
                                    <img src={'/assets/images/farid.svg'} className={'bdr-all-dark w-100'} alt={'Team Spacefit'}/>
                                </div>
                            </div>
                            <div className={'col-md-8 order-xl-1 order-lg-2 order-2  pt-lg-0 pt-4'}>
                                <div className={'team-details to-right'}>
                                    <h5 dangerouslySetInnerHTML={{__html: t('team.team6')}}/>
                                    <p>
                                        {t('team.description6')}
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



            </section>

    )
}
export default Team;