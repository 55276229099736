import React from "react";
import {useTranslation} from "react-i18next";
import TypeWriterEffect from "./TypeWriterEffect";


const Hero = () => {
    const {t} = useTranslation();
    return (
        <>
            <section className=" ">
                <div className="container-fluid p-0">
                    <div className="section-top">
                        <div className="">
                            <div className="img-box">
                                <div className="img_box-inner position-relative bg-top-home">
                                    <img src="/assets/images/home/bg-top-home.png" alt={'Spacefit'} className={'w-100'}/>
                                    <div className={'position-absolute layer-top d-lg-none d-block'}>
                                        <img src={'/assets/images/home/home-layer.png'} alt={'Spacefit'}/>
                                    </div>
                                    <div className={'position-absolute layer-top-left d-lg-none d-block'}>
                                        <img src={'/assets/images/home/layer-left.png'} alt={'Spacefit'}/>
                                    </div>

                                        <div className="text-header-centered position-absolute">

                                            <h2>
                                                <span className="text-center d-block">{t('home.hero.title')}</span>
                                                <span className="text-center d-block">{t('home.hero.title_2')}</span>
                                            </h2>
                                            <TypeWriterEffect />

                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Hero;