import React from "react";
import Cookies from "js-cookie";

const Video = () => {
    const currentLanguageCode = Cookies.get("i18next") || "en";
    return (
        <>
            <section className="arrival_section video bg-main">
                <div className="container video">
                    <div className="box box-video">
                        {/*<div className={'col-md-12'}>*/}
                            <video controls   loop className={'w-100'}>
                                <source src={`/assets/videos/video-presentation-${currentLanguageCode}.mp4`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        {/*</div>*/}

                    </div>
                </div>
            </section>
        </>
    )
}
export default Video;