import React from "react";
import {SocialMeta} from "../../layout/base/social-meta";
import {t} from "i18next";





const Privacy = () => {


    return (
        <>
            <SocialMeta title={`Politique de protection des données personnelles Spacefit.ai`} />
            <div className={' bg-light-color'}>
                <div className={'container'}>
                    <div className={'row justify-content-center pt-5'}>
                        <div className={'col-md-8'}>
                            <h1 className="secondary-h1 text-center w-100">{t('privacy.title')}</h1>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <div className="mb-4">
                                <div><strong>{t('privacy.t1')}</strong></div>
                                <div className={'fs-16'} dangerouslySetInnerHTML={{__html : t('privacy.t1P1')}} />

                            </div>

                            <div className="mb-4">
                                <div><strong>{t('privacy.t2')}</strong></div>
                                <div className={'fs-16'} dangerouslySetInnerHTML={{__html : t('privacy.t2P2')}} />

                            </div>

                            <div className="mb-4">
                                <div><strong>{t('privacy.t3')}</strong></div>
                                <div className={'fs-16'} dangerouslySetInnerHTML={{__html : t('privacy.t3P3')}} />

                            </div>

                            <div className="mb-4 custom-table">
                                <table>
                                    <tr>
                                        <th>{t('privacy.table.column1')}</th>
                                        <th>{t('privacy.table.column2')}</th>
                                    </tr>
                                    <tr>
                                        <td>{t('privacy.table.row1Col1')}</td>
                                        <td>{t('privacy.table.row1Col2')}</td>
                                    </tr>

                                    <tr>
                                        <td>{t('privacy.table.row2Col1')}</td>
                                        <td>{t('privacy.table.row2Col2')}</td>
                                    </tr>

                                    <tr>
                                        <td>{t('privacy.table.row3Col1')}</td>
                                        <td>{t('privacy.table.row3Col2')}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('privacy.table.row4Col1')}</td>
                                        <td>{t('privacy.table.row4Col2')}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('privacy.table.row5Col1')}</td>
                                        <td>{t('privacy.table.row5Col2')}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('privacy.table.row6Col1')}</td>
                                        <td>{t('privacy.table.row6Col2')}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('privacy.table.row7Col1')}</td>
                                        <td>{t('privacy.table.row7Col2')}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('privacy.table.row8Col1')}</td>
                                        <td>{t('privacy.table.row8Col2')}</td>
                                    </tr>
                                </table>
                            </div>

                            <div className="mb-4">
                                <div><strong>{t('privacy.t4')}</strong></div>
                                <div className={'fs-16'} dangerouslySetInnerHTML={{__html : t('privacy.t4P4')}} />
                            </div>

                            <div className="mb-4">
                                <div><strong>{t('privacy.t5')}</strong></div>
                                <div className={'fs-16'} dangerouslySetInnerHTML={{__html : t('privacy.t5P5')}} />
                            </div>

                            <div className="mb-4">
                                <div><strong>{t('privacy.t6')}</strong></div>
                                <div className={'fs-16'} dangerouslySetInnerHTML={{__html : t('privacy.t6P6')}} />
                            </div>

                            <div className="mb-4">
                                <div><strong>{t('privacy.t7')}</strong></div>
                                <div className={'fs-16'} dangerouslySetInnerHTML={{__html : t('privacy.t7P7')}} />
                            </div>


                            <div className="mb-4">
                                <div className={'fs-16'} dangerouslySetInnerHTML={{__html : t('privacy.t8P8')}} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};

export default Privacy;
