import { useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import Header from "./layout/base/Header";
import Footer from "./layout/base/Footer";
declare global {
    interface Window {
        $crisp: any[];
        CRISP_WEBSITE_ID: string;
    }
}
const ChatScript = () => {
    useEffect(() => {
        // Load the Crisp chat script dynamically
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "d73c5962-28e4-44eb-957f-f0bd58b6faa7";
        const script = document.createElement("script");
        script.src = "https://client.crisp.chat/l.js";
        script.async = true;
        document.head.appendChild(script);
    }, []);

    return null;
};
const App = () => {
    const location = useLocation();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <>
            {!location.pathname.includes("/payment") && <ChatScript />}
            <Header/>
            <Outlet/>
            <Footer/>
        </>
    )
}

export {App}
