import React, {FC} from "react";
import {SocialMeta} from "../../layout/base/social-meta";
import {t} from "i18next";


const Terms: FC = () => {


    return (
        <>
            <SocialMeta title={`Conditions générales d’utilisation des services de spacefit.ai`} />

            <div className={' bg-light-color'}>
              <div className={'container'}>
                  <div className={'row justify-content-center pt-5'}>
                      <div className={'col-md-8'}>
                          <h1 className="secondary-h1 text-center w-100">{t('terms.title')}</h1>
                      </div>
                  </div>
                  <div className={'row'}>
                      <div className={'col-md-12'}>
                          <div className="mb-4">
                              <div><strong>{t('terms.t1')}</strong></div>
                              <p className={'fs-16'}>
                                  {t('terms.t1P1')}
                                  </p>
                          </div>

                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t2')}</strong></div>
                              <ul dangerouslySetInnerHTML={{__html: t('terms.t2P2')}} />
                          </div>

                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t3')}</strong></div>
                              <p>{t('terms.t3P3')} </p>
                          </div>

                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t4')}</strong></div>
                              <p>{t('terms.t4P4')} </p>
                          </div>

                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t5')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t5P5')}} />
                          </div>

                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t6')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t6P6')}} />
                          </div>

                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t7')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t7P7')}} />
                          </div>

                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t8')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t8P8')}} />
                          </div>
                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t9')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t9P9')}} />
                          </div>
                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t10')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t10P10')}} />
                          </div>
                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t11')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t11P11')}} />
                          </div>
                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t12')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t12P12')}} />
                          </div>
                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t13')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t13P13')}} />
                          </div>

                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t14')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t14P14')}} />
                          </div>

                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t15')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t15P15')}} />
                          </div>

                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t16')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t16P16')}} />
                          </div>

                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t17')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t17P17')}} />
                          </div>

                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t18')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t18P18')}} />
                          </div>

                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t19')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t19P19')}} />
                          </div>

                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t20')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t20P20')}} />
                          </div>

                          <div className="mb-4 fs-16">
                              <div><strong>{t('terms.t21')}</strong></div>
                              <div dangerouslySetInnerHTML={{__html: t('terms.t21P21')}} />
                          </div>


                      </div>
                  </div>
              </div>
            </div>


        </>
    );
};

export default Terms;
