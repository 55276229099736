import React, { useEffect } from 'react';
import {useTranslation} from "react-i18next";

// Define TxtType class
class TxtType {
    constructor(private element: HTMLElement, private toRotate: string[], private period: string | null) {
        this.loopNum = 0;
        this.isDeleting = false;
        this.txt = '';
        this.tick();
    }

    private loopNum: number;
    private isDeleting: boolean;
    private txt: string;

    private tick() {
        const i = this.loopNum % this.toRotate.length;
        const fullTxt = this.toRotate[i];

        if (this.isDeleting) {
            this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
            this.txt = fullTxt.substring(0, this.txt.length + 1);
        }

        this.element.innerHTML = '<span class="wrap">' + this.txt + '</span>';

        let delta = 150; // Base delay

        if (this.isDeleting) {
            delta /= 2; // Decrease the delay when deleting characters for smoother effect
        }

        if (!this.isDeleting && this.txt === fullTxt) {
            delta = parseInt(this.period || '1000', 10); // Use provided period for delay
            this.isDeleting = true;
        } else if (this.isDeleting && this.txt === '') {
            this.isDeleting = false;
            this.loopNum++;
            delta = 40; // Shorter delay after deletion
        }

        setTimeout(() => {
            this.tick();
        }, delta);
    }

}

const TypeWriterEffect: React.FC = () => {
    const {t} = useTranslation();
    useEffect(() => {
        const elements = document.getElementsByClassName('writer');
        for (let i = 0; i < elements.length; i++) {
            const toRotate = elements[i].getAttribute('data-type');
            const period = elements[i].getAttribute('data-period');
            if (toRotate) {
                new TxtType(elements[i] as HTMLElement, JSON.parse(toRotate), period);
            }
        }
        // INJECT CSS
        const css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = ".writer > .wrap { border-right: 0.10em solid #7CEBB6}";
        document.body.appendChild(css);

        // Cleanup function
        return () => {
            document.body.removeChild(css);
        };
    }, []);

    return (
        <div className="row w-100 mt-3">
            <div className="col-12 col-lg-6 text-center text-lg-end type-writer-effect" style={{ paddingRight: "0 !important" }}>
                {t('home.hero.description')}
            </div>
            <div className="col-12 col-lg-6 text-center text-lg-start text-end type-writer-effect" >
                <span className="writer" data-period="1000" data-type={t('home.hero.effectText')}>
                    <span className="wrap"></span>
                </span>
            </div>
        </div>
    );
};

export default TypeWriterEffect;

