import React, {FC,  useState} from "react";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import Tooltip from 'react-bootstrap/Tooltip';
type Props = {
    title ?:any
    description ?:any
    subtitle ?:any
    isHighlighted?: boolean
    countries ?: any


}


const PriceCardFreemium: FC<Props> = ({title , description, subtitle , isHighlighted, countries}) => {
    const {t} = useTranslation();
    const currentLanguageCode = Cookies.get("i18next") || "en";
    const [showModal, setShowModal] = useState(false);
    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            Simple tooltip
        </Tooltip>
    );
    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleSubscribeButtonClick = () => {
        setShowModal(true);
    };
    return (
        <>
            <div  style={isHighlighted ? {   boxShadow: '0px 0px 30px 0px #CCCCCC' , transform: 'scale(1.04)', border: "2px solid #ABEC78"} : {}} >
                <div  className={'bg-green p-4 card-hero position-relative'}>
                    <div className={'inline-title'}>{t("pricing.freemium.title")}</div>
                    <div className={'sm-txt mt-3 h-140px'} >
                        {t("pricing.freemium.description")}
                    </div>


                </div>
                <div className={'title-price bg-light-green'}>
                    <div className={'h-70px pt-3'} dangerouslySetInnerHTML={{__html: t('pricing.freemium.subtitle')}} />
                </div>
                <div className={'bg-white'}>
                    <div className={'features'}>
                        <ul>
                            <li>{t('pricing.features.one')} <span className="check"><img src="/assets/images/check.png"  alt={'Spacefit'}/></span></li>
                            <li>{t('pricing.features.two')} <span className="check"><img src="/assets/images/check.png"  alt={'Spacefit'}/></span></li>
                            <li>{t('pricing.features.three')}<span className="check"><img src="/assets/images/check.png"  alt={'Spacefit'}/></span></li>


                        </ul>

                        <div className={'row justify-content-center'}>
                            <div className={'col-md-11'}>
                                <button className="btn-secondary-grey w-100" dangerouslySetInnerHTML={{__html: t('pricing.freemium.button')}} />


                            </div>
                            <div className={'col-md-11'}>
                                <button className="btn btn-nav-primary p-btn w-100 ms-0"
                                        onClick={handleSubscribeButtonClick}>{t('buttons.comingSoon')}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*{countries && <PurchaseForm showModal={showModal} onHide={() => setShowModal(false)} countries={countries} price={0} packageId={1}  paymentGateway={null} appURL={'freemium'}/>*/}
            {/*}*/}

        </>
    )
}
export default PriceCardFreemium;