import React from "react";
import {useTranslation} from "react-i18next";
const Client = () => {
    const {t} = useTranslation();
    return (
        <>
            <section className="client_section activity position-relative" >
                <div className={'container'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-10 mt-5'}>
                            <div className={'bg-primary-color'}>
                                <h1 className={'main-h1'}>      {t('home.client.title')}</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="">

                        <div className=" d-lg-block d-none">
                            <div className="position-absolute lft-top"><span className=""><img src="/assets/images/bg-first-equipe.png"  alt={'Spacefit'}/></span>
                            </div>
                            <div className="position-absolute rgt-top"><span className=""><img src="/assets/images/bg-top-b.png"  alt={'Spacefit'}/></span>
                            </div>
                        </div>


                    </div>


                </div>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className="col-md-3 block-exploitez">

                            <span className="img-potentiel"><img src="/assets/images/bg-algorithme.svg" alt={'Spacefit'} /></span>
                            <span className="titre-desc text-potentiel p-2"><span
                                className=" f-bold ">{t('home.client.robustTitle')}</span>
                                {t('home.client.robustDescription')}
                        </span>
                        </div>

                        <div className="col-md-3 block-exploitez">
                            <span className="img-potentiel"><img src="/assets/images/bg-resultat.svg" alt={'Spacefit'} /></span>
                            <span className="titre-desc text-potentiel p-2"><span
                                className=" f-bold">{t('home.client.resultTitle')}</span>
                                    <span className={''}
                                          dangerouslySetInnerHTML={{__html: t('home.client.resultDescription')}}/>

                        </span>
                        </div>

           <div className={`col-md-3 block-exploitez `}>
                            <span className="img-potentiel"><img src="/assets/images/bg-maximiser.svg" alt={'Spacefit'}/></span>
                            <div className="titre-desc text-potentiel p-2">
                                <span className=" f-bold">{t('home.client.reduceTitle')}</span>
                                <span dangerouslySetInnerHTML={{__html: t('home.client.reduceDescription')}}/></div>
                        </div>
                        <div className={`col-md-3 block-exploitez`}>
                            <span className="img-potentiel"><img src="/assets/images/bg-reduisez.svg"  alt={'Spacefit'}/></span>
                            <span className="titre-desc text-potentiel p-2">
                                 <span className=" f-bold">{t('home.client.maximizeTitle')}</span>
                                <small className="titre-desc"
                                       dangerouslySetInnerHTML={{__html: t('home.client.maximizeDescription')}}/>
                        </span>

                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}
export default Client;