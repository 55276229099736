import React from "react";
import {useTranslation} from "react-i18next";

const Bandeau = () => {
    const {t} = useTranslation();
    return (
        <>
            <section className="bandeau_section">
                <div className="container-fluid">
                    <div className="box">
                        <div className="row">
                            <div className="col-md-12 ml-auto">
                                <div className="heading_container remove_line_bt">
                                    <h3>
                                        {t('home.bandeau.title')}
                                        <span> {t('home.bandeau.subtitle')}</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Bandeau;