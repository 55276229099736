import React from "react";
import Hero from "./components/Hero";
import Video from "./components/Video";
import Bandeau from "./components/Bandeau";
import Client from "./components/Client";
import CarouselItems from "./components/Carousel";
import WhySpaceFit from "./components/WhySpaceFit";
import {SocialMeta} from "../../layout/base/social-meta";
import {useTranslation} from "react-i18next";



const Home = () => {

    const {t} = useTranslation();
    return (
        <>
            <SocialMeta title={t('navigation.home') } />
            <Hero />
            <Video />
            <Bandeau />
            <Client />
            <CarouselItems />
            <WhySpaceFit />
        </>
    );
};

export default Home;
