import React from "react";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";


const Space = () => {
    const {t} = useTranslation();
    const currentLanguageCode = Cookies.get("i18next") || "en";
    return (
        <>
            <div className={'position-relative'}>
                <div className={'d-lg-block d-none'}>
                    <div className="bg-equipe-t-l">
                        <img src="/assets/images/bg-first-equipe.png"  alt={'Spacefit'}/>
                    </div>
                    <div className="bg-equipe-b-r">
                        <img src="/assets/images/bg-first-equipe.png"  alt={'Spacefit'}/>
                    </div>
                </div>

                <div className={'container '}>
                        <div className={'row justify-content-center'}>
                            <div className={'col-md-10 mt-5'}>
                                <div className={'bg-primary-color'}>
                                    <h1 className={'main-h1'} dangerouslySetInnerHTML={{__html: t('explore.space.title')}} />
                                </div>
                            </div>

                            <div className={'space-list'}>
                                <div className={'col-md-12'}>
                                    <div className={'row '}>
                                        <div className={'col-xl-6 col-lg-12 col-md-12'}>
                                            <div className={' border-right-primary'}>
                                                <div className={'border-dot me-4'}>
                                                    <div className={'row align-items-center mb-5 pt-3 mt-5'}>
                                                        <div className={'col-3'}>
                                                            <img src="/assets/images/bg-time.svg" className={'w-100'}  alt={'Spacefit'}/>
                                                        </div>
                                                        <div className={'col-9'}>
                                                            <p className={'space-p'} dangerouslySetInnerHTML={{__html: t('explore.space.spaceP1')}} />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={'border-dot me-4'}>
                                                    <div className={'row align-items-center mb-5 pt-3 mt-5'}>
                                                        <div className={'col-3'}>
                                                            <img src="/assets/images/bg-human.svg" className={'w-100'}  alt={'Spacefit'}/>
                                                        </div>
                                                        <div className={'col-9'}>
                                                            <p className={'space-p'} dangerouslySetInnerHTML={{__html: t('explore.space.spaceP2')}} />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'border-dot me-4'}>
                                                    <div className={'row align-items-center mb-5 pt-3 mt-5'}>
                                                        <div className={'col-3'}>
                                                            <img src="/assets/images/iteraty.svg" className={'w-100'}  alt={'Spacefit'}/>
                                                        </div>
                                                        <div className={'col-9'}>
                                                            <p className={'space-p'} dangerouslySetInnerHTML={{__html: t('explore.space.spaceP3')}} />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'bdr-md-r-dot'}>
                                                    <div className={'row align-items-center mt-5 pt-3 mb-5'}>
                                                        <div className={'col-3'}>
                                                            <img src="/assets/images/bg-efficiency.svg" className={'w-100'}  alt={'Spacefit'}/>
                                                        </div>
                                                        <div className={'col-9'}>
                                                            <p className={'space-p me-2'} dangerouslySetInnerHTML={{__html: t('explore.space.spaceP4')}} />

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className={'col-xl-6 col-lg-12 col-md-12'}>
                                            <div className={'border-dot'}>
                                                <div className={'row align-items-center mb-5 pt-3 mt-5'}>
                                                    <div className={'col-3 order-xl-2 order-lg-1 order-1'}>
                                                        <img src="/assets/images/bg-wrap.svg" className={'w-100'}  alt={'Spacefit'}/>
                                                    </div>
                                                    <div className={'col-9 order-xl-1 order-lg-2 order-2'}>
                                                        <p className={'space-p'} dangerouslySetInnerHTML={{__html: t('explore.space.spaceP5')}} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'border-dot'}>
                                                <div className={`row align-items-center mb-5 ${currentLanguageCode === 'fr' && 'pb-lg-3'} pt-3 mt-5`}>
                                                    <div className={'col-3 order-xl-2 order-lg-1 order-1'}>
                                                        <img src="/assets/images/bg-nobias.svg" className={'w-100'}  alt={'Spacefit'}/>
                                                    </div>
                                                    <div className={'col-9 order-xl-1 order-lg-2 order-2'}>
                                                        <p className={'space-p'} dangerouslySetInnerHTML={{__html: t('explore.space.spaceP6')}} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'border-dot'}>
                                                <div className={`row align-items-center mb-5 ${currentLanguageCode === 'fr' && 'pb-lg-3'} pt-3 mt-5`}>
                                                    <div className={'col-3 order-xl-2 order-lg-1 order-1'}>
                                                        <img src="/assets/images/bg-thousand.svg" className={'w-100'}  alt={'Spacefit'}/>
                                                    </div>
                                                    <div className={'col-9 order-xl-1 order-lg-2 order-2'}>
                                                        <p className={'space-p me-2'} dangerouslySetInnerHTML={{__html: t('explore.space.spaceP7')}} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'row align-items-center mt-5 pt-3 mb-5'}>
                                                <div className={'col-3 order-xl-2 order-lg-1 order-1'}>
                                                    <img src="/assets/images/bg-powred.svg" className={'w-100'}  alt={'Spacefit'}/>
                                                </div>
                                                <div className={'col-9 order-xl-1 order-lg-2 order-2'}>
                                                    <p className={'space-p'} dangerouslySetInnerHTML={{__html: t('explore.space.spaceP8')}} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            </div>

        </>
    )
}
export default Space;