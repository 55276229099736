import React from "react";
import ExplorePower from "./components/ExplorePower";
import SimulateComponent from "./components/Simulate";
import Space from "./components/Space";
import {SocialMeta} from "../../layout/base/social-meta";
import {t} from "i18next";




const Technology = () => {


    return (
        <>
            <SocialMeta title={t('navigation.technology') } />
            <ExplorePower />
            <SimulateComponent />
            <Space />
        </>
    );
};

export default Technology;
