import React, { useEffect } from 'react';

const CalendlyComponent: React.FC = () => {
    useEffect(() => {
        // This will create a new instance of the Calendly widget when the component mounts
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="calendly-container mb-5">
             {/*Insert the Calendly inline widget here */}
            <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/spacefit-ai/30min"
                style={{ width: '100%', height: '730px'}} />


        </div>
    );
};

export default CalendlyComponent;
