import React from "react";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {Carousel} from "react-bootstrap";

const CarouselItems = () => {
    const {t} = useTranslation();
    const currentLanguageCode = Cookies.get("i18next") || "en";
    return (
        <section className="bg-cmarche">
            <div className={'container'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-md-10 mt-5'}>
                        <div className={'bg-primary-color'}>
                            <h1 className={'main-h1'}> {t('home.carousel.title')}</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'container'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-md-12 mb-5'}>
                        <Carousel data-bs-theme="dark">

                            {/*{[1, 2, 3].map((item, index) => (*/}
                            <Carousel.Item>
                                <div className="">
                                    <img
                                        className="d-block w-100 carousel-img"
                                        src={`/assets/images/slider/${currentLanguageCode}/slider-1.svg`}
                                        alt="First slide"
                                    />

                                </div>
                                <Carousel.Caption>

                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item>
                                <div className="">
                                    <img
                                        className="d-block  w-100 carousel-img"
                                        src={`/assets/images/slider/${currentLanguageCode}/slider-2.svg`}
                                        alt="First slide"
                                    />

                                </div>
                                <Carousel.Caption>

                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item>
                                <div className="">
                                    <img
                                        className="d-block  w-100 carousel-img"
                                        src={`/assets/images/slider/${currentLanguageCode}/slider-3.svg`}
                                        alt="First slide"
                                    />

                                </div>
                                <Carousel.Caption>

                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item>
                                <div className="">
                                    <img
                                        className="d-block  w-100 carousel-img"
                                        src={`/assets/images/slider/${currentLanguageCode}/slider-4.svg`}
                                        alt="First slide"
                                    />

                                </div>
                                <Carousel.Caption>

                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item>
                                <div className="">
                                    <img
                                        className="d-block  w-100 carousel-img"
                                        src={`/assets/images/slider/${currentLanguageCode}/slider-5.svg`}
                                        alt="First slide"
                                    />

                                </div>
                                <Carousel.Caption>

                                </Carousel.Caption>
                            </Carousel.Item>
                            {/*))}*/}


                        </Carousel>
                    </div>
                </div>

            </div>

        </section>
    )
}
export default CarouselItems;