import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import PurchaseForm from "./PurchaseForm";
type Props = {
    package_id ?:any
    countries ?:any
    showModal: boolean;
    onHide: () => void;
}

const Slider: React.FC<Props> = ({package_id, countries, onHide, showModal}) => {
    const {t} = useTranslation();
    const [price, setPrice] = useState<any>(1000);
    const handleModalClose = () => {
        onHide();
    };

    const updateSliderValue = (value: number) => {
        if (value < 999) {
            value = 500;
        } else if (value >= 100000) {
            value = 100000;
        } else {
            value = value + (500 - (value % 500));
        }

        // Update price state based on slider value
        setPrice(value * 2);
    };

    useEffect(() => {
        const slider = document.getElementById("myRange") as HTMLInputElement;
        // const output = document.getElementById("demo");
        const output2 = document.getElementsByClassName("demo")[0];
        // const outputMettrage = document.getElementById("mettrage-calcule");
        const outputMettrage2 = document.getElementsByClassName("area-bg")[0];

        const updateSliderValue = () => {
            let value = parseInt(slider.value);
            if (value < 999) {
                value = 500;
            } else if (value >= 100000) {
                value = 100000;
            } else {
                value = value + (500 - (value % 500));
            }
            // output!.innerHTML = (value * 2).toString() + ' €'; // Add null assertion operator (!) to output
            // outputMettrage!.innerHTML = value + ' m²'; // Add null assertion operator (!) to outputMettrage
            output2!.innerHTML = new Intl.NumberFormat('en-US').format(value * 2).replace(/\,/g, ' ').toString() + ' €' + '<br/>' + '<small class="fs-12 fw-normal"> 2 € / m²</small>';
            outputMettrage2!.innerHTML = '<div class="area-text">'+  new Intl.NumberFormat('en-US').format(value ).replace(/\,/g, ' ')  +'</div>' ; // Add null assertion operator (!) to outputMettrage
            setPrice(value * 2)
        };

        slider.addEventListener('input', updateSliderValue);

        return () => {
            slider.removeEventListener('input', updateSliderValue);
        };
    }, [price]);

    return (
<>
    <div className={'bg-blue p-4 card-hero position-relative'}>
        <div className={'inline-title'}>{t('pricing.one.title')}</div>
        <div className={'sm-txt mt-3 '}>
            {t('pricing.one.description')}

            <div>
                <div className="slidecontainer" style={{position: 'absolute'}}>
                    <div className={'mettrage-calcule text-start d-flex w-100'}>
                        <div className={'pt-1'}>
                            {t('pricing.one.area')}:
                        </div>
                        <div className={'area-bg ms-1'}>
                            <div className={'area-text '}> 500  </div>
                        </div>
                        <div className={'dark-txt ms-2'}>
                            m²
                        </div>
                    </div>
                    <div className="range">
                        <input className='slider' name="rangeslider" type="range" min="500" max="100000"
                               defaultValue="500" id="myRange"/>

                    </div>
                    <span className="metrage" id="">500 m²</span>
                    <span className={'f-t-b'} style={{float: 'right'}} id="">{new Intl.NumberFormat('en-US').format(100000).replace(/\,/g, ' ')}  m²</span>

                </div>
            </div>
        </div>
    </div>
    <div className={'title-price bg-light-blue'}>
        <div className={'h-70px pt-3 demo'} >

            1 000 €  <br/> <small className='fs-12 fw-normal'> 2 € / m²</small>


        </div>
    </div>

    {countries && <PurchaseForm showModal={showModal} onHide={() => handleModalClose()} countries={countries} price={price} packageId={2} paymentGateway={1} appURL={'one/stripe'} packageName={'One'} />}

</>


)
    ;
};

export default Slider;
